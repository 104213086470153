.transCard {
    background: none !important;
    border: 1px solid #818181;
    border-radius: 20px !important;
    color: #fff;
    padding: 0 !important
}

.singleCLBCard {
    background: #000 !important;
    border: 1px solid #818181;
    border-radius: 20px !important;
    color: #fff;
    padding: 10px !important
}



.dropCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 12px;
    background: #484848;
    border-radius: 12px;
}


.grayCard {
    display: flex;
    align-items: center;
    background: #484848;
    justify-content: center;
    padding: 5px !important;
    border: none !important;
    border-radius: 10px !important;
}

.propertyCard {
    border: none !important;
    border-radius: 10px !important;
    background-color: #000 !important;
    border: 0.5px solid #fff !important;
    padding: 10px !important;
    height: 100%;
}