.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  // width: 100%;

  .container {
    width: 100vw;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .dropCLb {
      background-color: #484848;
      border-radius: 10px;
    }

    .coverImg {
      width: 100%;
      height: 300px;
    }

    .avtivOptBtn {
      color: #35abae !important;
      font-size: 18px;
    }

    .InAvtivOptBtn {
      color: #fcfcfc !important;
      font-size: 18px;
    }

    .avtivFollowSectionBtn {
      color: #35abae !important;
    }

    .InAvtivFollowSectionBtn {
      color: #999999 !important;
    }
  }
}

.emailField {
  label {
    color: #fff !important;
  }
}
