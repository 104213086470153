@mixin main() {
  background-image: url("../assets/png/bgImg2.png");
  background-attachment: fixed;
  background-size: cover;

  .clbSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .activeFilter {
    background: #35ABAF !important;
    border-radius: 20px !important;
  }

  .inactiveFilter {
    color: #35ABAF !important;
    background: #484848 !important;
    border-radius: 20px !important;
  }


  .price {
    font-weight: 700 !important;
  }

  .startBidBtn {
    color: #35ABAF;
  }

  .startPurchaseBtn {
    color: #35ABAF !important;
  }
}

.main {
  @include main();
}

.activeFilterSort {
  color: #35ABAF !important;
}

.loadinMain {
  @include main();
  height: 100vh !important;
}
.redText {
  color: #f50057 !important;
}