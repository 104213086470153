.container {
  width: 100%;
  /* height: fit-content; */
  height: 100%;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
}
.contained {
  width: 90%;
  margin-top: 120px;
  height: fit-content;
}
.head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.head h1 {
  font-weight: 700;
  font-size: 32px;
}
.head div{
  width: 100px;
}
.head p{
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
