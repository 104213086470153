.main {
  background-image: url("../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  // width: 100%;

  .container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
  }
  .transCard{
    height: 50%;
    margin: auto;
    padding: 0%;
    width: 100%;
  }

}


