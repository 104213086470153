@mixin auth {
  background-color: #35abaf !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUpOrg {
  @include auth();
  height: 100%;
}

.signUp {
  @include auth();
  // height: 83%;
  border-radius: 16px;
  background: var(
    --gradient2-for-buttons,
    linear-gradient(0deg, #114345 0%, #114345 100%),
    linear-gradient(96deg, #114345 0.42%, rgba(5, 5, 5, 0.4) 100%)
  );
  display: flex;
  padding: 48px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.signInText {
  color: #35abaf !important;
  font-family: Montserrat;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.signIn {
  @include auth();
  height: 100vh;
}

.bgImage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;

  .sideImg {
    width: 60% !important;
  }
}

.direct {
  width: 40px;
  height: 45px;
  padding: 10px;
  background: white !important;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.login {
  margin: 60px 96px;
  height: 100%;
}

@media only screen and (max-width: 400px) {
  .bgImage {
    display: none !important;

    .sideImg {
      width: 100% !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .login {
    margin: auto;
    height: auto;
    max-width: 80%;
    padding: auto;
    margin-top: 20%;
  }
}
