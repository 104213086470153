.main {
  background-image: url("../../../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.container {
  width: 100%;
  /* height: fit-content; */
  height: 100%;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  font-family: "DM Sans";
  font-style: normal;
}
.container div {
  width: 100%;
}
.heading {
  width: 90%;
  margin-top: 8%;
  height: fit-content;
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  font-size: 1.3rem;
}
.heading h1 {
  cursor: pointer;
}
.active {
  color: #35abaf;
}
.whowe {
  width: 90% !important;
  margin: 2rem auto;
}
.whowe p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
.choose {
  /* border: 2px solid red; */
  margin-top: 5%;
}

.getInTouch {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content:center;
  margin-top:2rem
}
