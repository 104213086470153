.main {
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  position: absolute;
  top: 0%;
  right: 0%;

  .main_ {
    background-color: #232323;
    padding: 20px;
    color: #fff;
    width: 400px;
  }
}
