.main {
  background-image: url("../../assets/png/bgImg2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.container {
  width: 100%;
  /* height: fit-content; */
  height: 100%;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
}
.contained {
  margin-top: 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  width: 513px;
  /* height: 489px; */
  background: #232323;
  border-radius: 12px;
}
.contained h1 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #fcfcfc;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.contained p {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}
.text {
  width: 100%;
  border-radius: 4px;
  text-underline-offset: none;
  /* margin-top: 10px; */
}

.btn_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 364px;
  height: 44px;
  background: #35abaf;
  border-radius: 30px;
  flex: none;
  flex-grow: 0;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .contained {
    width: 90%;
  }
  .btn_box {
    width: 100%;
  }
}
