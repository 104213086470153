@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans";
  background-color: #000;
  overflow-y: auto; 
}
// .text-white-imp {
//   color: white !important;
// }
.text-white-imp label {
  color: white !important;
}
.text-white-imp div input:disabled {
  color: white !important;
}
input:disabled {
  // color: white !important;
  -webkit-text-fill-color: white !important;
}

.MuiInputLabel-root {
  color: white !important; 
}

.MuiInputLabel-root.Mui-disabled {
  color: white !important; 
}
