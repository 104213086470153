.main{
    background-image: url("../assets/png/bgImg2.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size:cover;
    // height: 100vh;
   
}


